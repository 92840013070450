import axios from "axios"
import "./generate.css"
import { useState } from "react"

function Generate () {
    const [trackingNumber, setTrackingNumber] = useState("")
    const [isSuccess, setIsSuccess] = useState(false);

    async function submit(e) {
        e.preventDefault();
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate`);
          setTrackingNumber(response.data.trackingNumber);
          setIsSuccess(true); // Set success status to true
          console.log(response.data);
        } catch (error) {
          console.error('Error generating tracking number', error);
          setIsSuccess(false); // Set success status to false in case of error
        }
    }
          
        
    
    return(
        <div className="tracking_result">
           <form className="generate_form" onSubmit={submit}>
<button>Generate Tracking Number</button>
        </form>
        <p> New Tracking No: <span className="new_tracking_no">{trackingNumber}</span></p>  
        {isSuccess && (
        <p> Go to <a href="/xgzKuYsMNEiZ">Admin</a></p>
      )}
        </div>
       

    )
}

export default Generate